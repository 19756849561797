body { 
  overflow-x: hidden;
}

p {
  font-size: 20px;
}

a {
  text-decoration: none;
}

a:hover {
  color:#00A0C6; 
  text-decoration:none; 
  cursor:pointer;  
}

.container-fluid {
  padding-right:0;
  padding-left:0;
  margin-right:auto;
  margin-left:auto
}

.Navbar {
  padding-right:0;
  padding-left:0;
  margin-right:auto;
  margin-left:auto
}

.bgContainer {
  padding-top: 100px;
  padding-bottom: 200px;
}

.heroContainer {
  position: relative;
  left: auto;
  right: auto;
}

.heroImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url("./Img/hero_main3.jpeg");
  height: 96vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 300px;
}

.hero-text {
    color: white;
}

.playFreeBtn {
  background-color: black;
  color: white;
  width: 300px;
  margin: 0 auto;
  padding: 20px 0;
  font-size:40px;
  border: 3px solid white;
}

.playFreeBtn:hover {
  background-color: #202020;
  transition: 500ms;
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  margin: 25% 50% 25% 50%;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid red;
  border-color: red transparent red transparent;
  animation: lds-hourglass 2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.valorant_logo {
  filter: invert(100%);
  padding: 50px 0;
}

.big_header {
  font-style: italic;
  font-weight: 800;
}

.weAreValorantBg {
  background-image: url(../src/Img/we_are_valorant_bg.png);
  /* height: 1000px; */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.yourAgentsBg {
  background-image: url(../src/Img/agents_bg.png);
  /* height: 1000px; */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.doubleAgentsContainer {
  text-align: center;
}

.doubleAgentsImage {
  text-align: center;
  /* width: auto;
  height: 900px; */
}

.yourMapsBg {
  background-image: url(../src/Img/maps_bg.jpeg);
  /* height: 1000px; */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.streamerBg {
  background-image: url(../src/Img/simple_dark.jpeg);
  /* height: 1000px; */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.esportsBg {
  background-image: url(../src/Img/esports_bg.jpeg);
  /* height: 1000px; */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.videoContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}

.footerContainer {
  color: white;
  background-color: black;
  text-align: center;
  padding: 50px 0 200px 0;
  font-size: 12px;
}

.downloadLink {
  font-size: 16px;
}

.aboutBg {
  background-image: url(../src/Img/about_bg.jpeg);
  /* height: 1000px; */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.aboutContainer {
  margin-top: 150px;
  margin-bottom: 300px;
}

.aboutFont {
  font-size: 1.5rem;
}

.phoenixImg {
  margin-top: 150px;
}

.accordionContainer {
  text-align: left;
  color: white;
  padding: 100px 0px;
}

.headerText {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  color: white;
  font-size: 4rem;
  font-style: italic;
  font-weight: 700;
  text-shadow: 2px 2px 8px #000000;
  padding-left: 25px;
}

.splashImg {
  width: 100%;
}

.agentBg {
  background-image: url(../src/Img/valorant_bg_white.jpeg);
  padding-top: 350px;
  height: 700px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.mapBg {
  background-image: url(../src/Img/valorant_bg_white.jpeg);
  padding-top: 350px;
  height: 700px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.bgHeader {
  font-size: 10rem;
  font-weight: 900;
  font-style: italic;
  text-align: right;
}

.displayIconImg {
  width: 100px;
  opacity: 0.3;
}

.displayIconImg:hover {
  opacity: 1;
  width: 110px;
  margin-bottom: -20px;
  margin-top: -5px;
  transition: 750ms;
}

.agentSelectContainer {
  margin: 300px auto 100px auto;
  border: 5px #ff4654;
  border-style: solid;
  padding: 30px;
}

.singleAgentContainer {
  margin: 0 auto 0 auto;
  width: 140px;
}

.agentContainer {
  margin: 100px 0 100px 0;
}

.fullPortraitImg {
  width: 140%;
  position: relative;
  right: 100px;
}

.agentInfo {
  margin: 50px 0 0 0;
}

.agentTitle {
  font-size: 5rem;
  font-weight: 800;
  font-style: italic;
  color: #ff4654;
}

.agentHeader {
  font-size: 3rem;
  font-weight: 800;
  font-style: italic;
  margin-top: 50px;
}

.agentDescription {
  font-size: 1.3rem;
}

.abilityContainer {
  font-size: 1.2rem;
  width: 75%;
}

.abilityIcon {
  width: 150px;
  filter: invert(100%);
}

.abilityIconContainer {
  padding: 30px;
  text-align: center;
}

.signupBg {
  background-image: url(../src/Img/signup_bg.jpeg);
  padding-top: 350px;
  height: 700px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.signinHeader {
  font-size: 10rem;
  font-weight: 900;
  font-style: italic;
  text-align: right;
}

/* React transitions CSS */
.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: 300ms ease-out;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: 300ms ease-in;
}



/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
  .big_header {
    font-size: 7rem;
  }
}

@media screen and (max-width: 990px) {
  .yourAgents_header {
    font-size: 6rem;
    font-weight: 900;
    font-style: italic;
    text-align: left;
  }
}

@media screen and (max-width: 850px) {
  .yourAgents_header {
    font-size: 5rem;
    font-weight: 900;
    font-style: italic;
    text-align: left;
  }
}

/* If the screen size is 720px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 720px) {
    .signinHeader {
      font-size: 6rem;
      font-weight: 900;
      font-style: italic;
      text-align: right;
    }

    .bgHeader {
      font-size: 6rem;
      font-weight: 900;
      font-style: italic;
      text-align: right;
    }

    .yourAgents_header {
      font-size: 6rem;
      font-weight: 900;
      font-style: italic;
      text-align: left;
    }

    .aboutContainer {
      margin-top: -75px;
    }

    .agentSelectContainer {
      margin-top: -25px;
    }
  } 

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .big_header {
    font-size: 5rem;
  }
  } 